'use client';

import React, { createContext, useContext, useState, ReactNode } from 'react';
import WORDING from '@/wording';
import { Config, LanguageOptions, ServiceType, Wording } from '@/types';

export type WordingType = Wording;

// Define the correct type for WORDING
export type WordingMap = {
  [key in ServiceType]: {
    [key in LanguageOptions]?: WordingType; // Optional because not all service types may have all languages
  };
};

// Context types
interface ConfigContextProps {
  config: Config; // Current configuration for the app
  setConfig: (config: Config) => void; // Function to update the config
  wording: WordingMap[ServiceType][LanguageOptions]; // Translations for the selected language
  updateWording: (serviceType: ServiceType, lang: LanguageOptions) => void; // Function to change the wording dynamically
  selectedLanguage: LanguageOptions; // Current langue for the app
  setSelectedLanguage: (language: LanguageOptions) => void; // Function to set a language for the app
  setLanguageAndWording: (
    serviceType: ServiceType,
    language: LanguageOptions
  ) => void; // Function to save selected language and change wording files used accordingly
}

// Create the context
const ConfigContext = createContext<ConfigContextProps | undefined>(undefined);

// Provider component
export const ConfigProvider = ({
  children,
  initialConfig,
  initialLanguage,
}: {
  children: ReactNode;
  initialConfig: Config;
  initialLanguage: LanguageOptions;
}) => {
  // State to store the current configuration
  const [config, setConfig] = useState<Config>(initialConfig);
  // State to store the current selected language, by default it's the first from the config
  const [selectedLanguage, setSelectedLanguage] =
    useState<LanguageOptions>(initialLanguage);

  // Function to update both selectedLanguage and wording
  const setLanguageAndWording = (
    serviceType: ServiceType,
    language: LanguageOptions
  ) => {
    setSelectedLanguage(language);
    updateWording(serviceType, language);
  };

  // State to store the current wording based on the initial config
  const initialWording =
    WORDING?.[initialConfig?.serviceType]?.[
      initialLanguage ?? initialConfig?.languages[0]
    ] ?? ({} as Wording);

  const [wording, setWording] = useState<Wording>(initialWording);

  // Function to update the wording dynamically
  const updateWording = (serviceType: ServiceType, lang: LanguageOptions) => {
    if (WORDING[serviceType]?.[lang]) {
      setWording(WORDING[serviceType][lang]);
    } else {
      console.error(`Wording not found for ${serviceType} and ${lang}`);
    }
  };

  // Values passed to the context
  const value: ConfigContextProps = {
    config,
    setConfig,
    wording,
    updateWording,
    selectedLanguage,
    setSelectedLanguage,
    setLanguageAndWording,
  };

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

// Hook to use the Config context and get the config
export const useConfig = (): ConfigContextProps => {
  const context = useContext(ConfigContext);

  if (!context) {
    console.warn("⚠️ useConfig() a été appelé en dehors d'un ConfigProvider.");
    return {
      config: {} as Config,
      setConfig: () => {},
      wording: {} as WordingMap[ServiceType][LanguageOptions],
      updateWording: () => {},
      selectedLanguage: '' as LanguageOptions,
      setSelectedLanguage: () => {},
      setLanguageAndWording: () => {},
    };
  }

  return context;
};
