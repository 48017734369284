import { Wording } from '@/types';

export const FITNESS_WORDING_RS: Wording = {
  components_header_videos_content: 'Vežbe',
  components_header_men: 'Za muškarce',
  components_header_women: 'Za žene',
  components_header_recipes: 'Recepti',
  components_header_blog: 'Blog',
  components_header_members: 'Članovi',
  hero_text: 'Održavajte svoje telo u punoj formi i snazi',
  hero_button_text: 'Započnite svoje fitnes putovanje',
  components_ressources_section_title: 'RESURSI',
  components_ressources_subheader_text:
    'Svi resursi koji su vam potrebni za zdrav život',
  components_ressources_span_text:
    'Brinite o sebi: Istražite raznovrsne resurse za sveobuhvatno zdravlje i kvalitetan život, uz vrhunske savete o fitnesu, ishrani i održivim životnim navikama.',
  components_ressources_card_1_title: 'Motivacija',
  components_ressources_card_1_text:
    'Trikovi i saveti za formiranje trajnih navika vežbanja.',
  components_ressources_card_2_title: 'Dijeta',
  components_ressources_card_2_text:
    'Praktični saveti za ishranu koji vode ka zdravijem i snažnijem životu.',
  components_ressources_link: 'Gledajte video zapise',
  components_notes_and_google_title_1: 'Fitnes trening',
  components_notes_and_google_title_2: 'Redovna rutina',
  components_notes_and_google_title_3: 'Održavanje dijete',
  components_review_google_text: 'Google recenzije',
  videos_content_section_title: 'VEŽBE',
  videos_content_section_subheader_text: 'Naši video snimci',
  text_men: 'Muškarci',
  text_women: 'Žene',
  videos_content_section_button_more: 'Više videa',
  blog_section_title: 'BLOG',
  blog_section_subheader_text: 'Članci i vesti',
  blog_section_button_more: 'Više članaka',
  components_recipes_section_title: 'RECEPTI',
  components_recipes_subheader_text: 'Zdrava ishrana za vaš aktivan život',
  components_recipes_span_text:
    'Otkrivajte ukusne i hranljive recepte koji podržavaju vaš aktivan stil života.',
  components_recipes_card_1_title: 'Započnite svoj dan',
  components_recipes_card_1_text:
    'Započnite svoja jutra bogatim i hranljivim opcijama za doručak.',
  components_recipes_card_3_title: 'Ručkovi za snagu',
  components_recipes_card_3_text:
    'Otkrijte ukusne i praktične recepte za ručak dizajnirane za vaš aktivan životni stil. Od šarenih salata do tortilja prepunih proteina, ovi recepti će lansirati vašu energiju u svemir!',
  components_recipes_card_2_title: 'Večere za oporavak',
  components_recipes_card_2_text:
    'Istražite razne nemasne proteine, integralne žitarice i živopisno povrće za zadovoljavajući završetak dana.',
  components_recipes_link: 'Pogledajte sve recepte',
  components_footer_text:
    'Podignite svoju snagu, zapalite svoju strast: vaša teretana, vaša transformacija.',
  components_footer_info: 'Info',
  components_footer_registration_number: 'Registracioni broj',
  components_footer_legal: 'Pravna',
  components_footer_terms: 'Uslovi i odredbe',
  components_footer_privacy: 'Politika privatnosti',
  button_load_more: 'Učitaj još',
  page_men_videos_content_hero_text: 'Vežbe za muškarce',
  page_videos_content_menu_all: 'Sve',
  page_videos_content_menu_biceps: 'Biceps',
  page_videos_content_menu_triceps: 'Triceps',
  page_videos_content_menu_back: 'Leđa',
  page_videos_content_menu_chest: 'Grudi',
  page_videos_content_menu_legs: 'Noge',
  page_videos_content_menu_shoulders: 'Ramena',
  page_videos_content_menu_abdominals: 'Trbušnjaci',
  page_women_videos_content_hero_text: 'Vežbe za žene',
  page_recipes_hero_text: 'Recepti',
  page_recipes_section_title: 'Ishrana',
  page_recipes_subheader_text: 'Recepti',
  page_articles_hero_text: 'Naš blog',
  page_articles_section_title: 'Blog',
  page_articles_subheader_text: 'Članci i vesti',
  enter_email_title: 'Unesite svoju e-mail adresu da biste nastavili',
  enter_email_placeholder_email: 'Email',
  enter_email_button_enter: 'Unesi',
  components_login_success_text: 'Пријава је успела!',
  components_card_link_video_content: 'Pusti video',
  components_card_link_recipe: 'Vidi recept',
  components_card_link_article: 'Vidi članak',
  france: 'Француска',
  cyprus: 'Кипар',
};
