import { Wording } from '@/types';

export const YOGA_WORDING_FR: Wording = {
  components_header_videos_content: 'Entraînements',
  components_header_men: '',
  components_header_women: '',
  components_header_recipes: 'Recettes',
  components_header_blog: 'Blog',
  components_header_members: 'Membres',
  hero_text: 'Gardez votre corps flexible et fort',
  hero_button_text: 'Commencez votre voyage de yoga',
  components_ressources_section_title: 'RESSOURCES',
  components_ressources_subheader_text:
    'Toutes les ressources dont vous avez besoin pour un style de vie harmonieux',
  components_ressources_span_text:
    'Découvrez les secrets du bien-être : plongez dans une collection de poses de yoga, de méditations de pleine conscience et de recettes saines pour créer un équilibre dans tous les aspects de votre vie.',
  components_ressources_card_1_title: 'Conseils de yoga',
  components_ressources_card_1_text:
    'Des conseils pratiques pour approfondir votre pratique et perfectionner vos poses.',
  components_ressources_card_2_title: 'Régime',
  components_ressources_card_2_text:
    'Conseils nutritionnels intelligents pour être en meilleure santé et plus fort.',
  components_ressources_link: 'Regarder des vidéos',
  components_notes_and_google_title_1: 'Entraînements de yoga',
  components_notes_and_google_title_2: 'Routine régulière',
  components_notes_and_google_title_3: 'Entretien du régime',
  components_review_google_text: 'Avis sur Google',
  videos_content_section_title: 'ENTRAÎNEMENTS',
  videos_content_section_subheader_text: 'Nos vidéos',
  text_men: '',
  text_women: '',
  videos_content_section_button_more: 'Plus de vidéos',
  blog_section_title: 'BLOG',
  blog_section_subheader_text: 'Articles et actualités',
  blog_section_button_more: "Plus d'articles",
  components_recipes_section_title: 'RECETTES',
  components_recipes_subheader_text: 'Nourrissez votre corps et votre esprit',
  components_recipes_span_text:
    'Des recettes saines pour soutenir votre pratique du yoga et améliorer votre bien-être.',
  components_recipes_card_1_title: 'Petits déjeuners énergisants',
  components_recipes_card_1_text:
    'Réveillez-vous avec des repas vibrants et énergisants.',
  components_recipes_card_3_title: 'Des déjeuners riches en énergie',
  components_recipes_card_3_text:
    "Découvrez des recettes de déjeuner délicieuses et pratiques conçues pour votre style de vie actif. Des salades colorées aux wraps riches en protéines, ces recettes vous permettent de rester concentré et plein d'énergie.",
  components_recipes_card_2_title: 'Dîners de récupération',
  components_recipes_card_2_text:
    'Explorez une variété de protéines maigres, de grains entiers et de légumes vibrants pour une fin de journée satisfaisante.',
  components_recipes_link: 'Voir toutes les recettes',
  components_footer_text:
    "Trouvez l'équilibre, respirez profondément : votre yoga, votre voyage.",
  components_footer_info: 'Informations',
  components_footer_registration_number: "Numéro d'enregistrement",
  components_footer_legal: 'Légal',
  components_footer_terms: 'Termes et conditions',
  components_footer_privacy: 'politique de confidentialité',
  button_load_more: 'Charger plus',
  page_men_videos_content_hero_text: 'Entraînements de yoga',
  page_recipes_hero_text: 'Recettes',
  page_recipes_section_title: 'Nutrition',
  page_recipes_subheader_text: 'Recettes',
  page_articles_hero_text: 'Notre blog',
  page_articles_section_title: 'Blog',
  page_articles_subheader_text: 'Articles et actualités',
  enter_email_title: 'Veuillez entrer votre email pour continuer',
  enter_email_placeholder_email: 'E-mail',
  enter_email_button_enter: 'Entrer',
  components_login_success_text: 'Connexion réussie !',
  components_card_link_video_content: 'Regarder maintenant',
  components_card_link_recipe: 'Voir la recette',
  components_card_link_article: "Voir l'article",
  france: 'France',
  cyprus: 'Chypre',
};
