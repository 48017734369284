import { Wording } from '@/types';

export const FITNESS_WORDING_EN: Wording = {
  components_header_videos_content: 'Workouts',
  components_header_men: 'For men',
  components_header_women: 'For women',
  components_header_recipes: 'Recipes',
  components_header_blog: 'Blog',
  components_header_members: 'Members',
  hero_text: 'Keep Your Body Fit & Strong',
  hero_button_text: 'Start Your Fitness Journey',
  components_ressources_section_title: 'RESOURCES',
  components_ressources_subheader_text:
    'All The Resources You Need For Healthy Living',
  components_ressources_span_text:
    'Nourish Your Well-Being: Unleashing a Wealth of Resources for Holistic Health and Wholesome Living, Including Expert Guidance on Fitness, Nutrition, and Sustainable Lifestyles.',
  components_ressources_card_1_title: 'Motivation',
  components_ressources_card_1_text:
    'Short bursts of motivation for lasting exercise habits.',
  components_ressources_card_2_title: 'Diet',
  components_ressources_card_2_text:
    'Smart nutrition tips for a healthier, stronger you.',
  components_ressources_link: 'Watch Videos',
  components_notes_and_google_title_1: 'Fitnes Training',
  components_notes_and_google_title_2: 'Regular Routine',
  components_notes_and_google_title_3: 'Diet Maintenance',
  components_review_google_text: 'Review on Google',
  videos_content_section_title: 'WORKOUTS',
  videos_content_section_subheader_text: 'Our videos',
  text_men: 'Men',
  text_women: 'Women',
  videos_content_section_button_more: 'More Videos',
  blog_section_title: 'BLOG',
  blog_section_subheader_text: 'Articles & News',
  blog_section_button_more: 'More Articles',
  components_recipes_section_title: 'RECIPES',
  components_recipes_subheader_text: 'Healthy Diet for Your Active Life',
  components_recipes_span_text:
    'Cooking Up Vitality: Explore a World of Flavorful Fitness with Our Quick and Nutrient-Packed Recipes.',
  components_recipes_card_1_title: 'Kickstart your day',
  components_recipes_card_1_text:
    'Start your mornings right with nutrient-packed, protein rich breakfast options.',
  components_recipes_card_3_title: 'Power-Packed Lunches',
  components_recipes_card_3_text:
    'Discover delicious and convenient lunch recipes designed for your active lifestyle. From colorful salads to protein-packed wraps, these recipes keep you fueled and focused.',
  components_recipes_card_2_title: 'Dinners for recovery',
  components_recipes_card_2_text:
    'Explore a variety of lean protein, whole grains, and vibrant vegetables for a satisfying end to your day.',
  components_recipes_link: 'View all recipes',
  components_footer_text:
    'Elevate Your Strength, Ignite Your Passion: Your Gym, Your Transformation.',
  components_footer_info: 'Info',
  components_footer_registration_number: 'Registration number',
  components_footer_legal: 'Legal',
  components_footer_terms: 'Terms and Conditions',
  components_footer_privacy: 'Privacy Policy',
  button_load_more: 'Load More',
  page_men_videos_content_hero_text: 'Men workouts',
  page_videos_content_menu_all: 'All',
  page_videos_content_menu_biceps: 'Biceps',
  page_videos_content_menu_triceps: 'Triceps',
  page_videos_content_menu_back: 'Back',
  page_videos_content_menu_chest: 'Chest',
  page_videos_content_menu_legs: 'Legs',
  page_videos_content_menu_shoulders: 'Shoulders',
  page_videos_content_menu_abdominals: 'Abdominals',
  page_women_videos_content_hero_text: 'Women workouts',
  page_recipes_hero_text: 'Recipes',
  page_recipes_section_title: 'Nutrition',
  page_recipes_subheader_text: 'Recipes',
  page_articles_hero_text: 'Our Blog',
  page_articles_section_title: 'Blog',
  page_articles_subheader_text: 'Articles & News',
  enter_email_title: 'Please enter your email to continue',
  enter_email_placeholder_email: 'Email',
  enter_email_button_enter: 'Enter',
  components_login_success_text: 'Login successful!',
  components_card_link_video_content: 'Watch Now',
  components_card_link_recipe: 'View Recipe',
  components_card_link_article: 'View Article',
  france: 'France',
  cyprus: 'Cyprus',
};
