import { Wording } from '@/types';

export const FITNESS_WORDING_FI: Wording = {
  components_header_videos_content: 'Harjoitukset',
  components_header_men: 'Miehille',
  components_header_women: 'Naisille',
  components_header_recipes: 'Reseptit',
  components_header_blog: 'Blogi',
  components_header_members: 'jäsenet',
  hero_text: 'Pidä kehosi kunnossa ja vahvana',
  hero_button_text: 'Aloita kuntomatkasi',
  components_ressources_section_title: 'RESURSSIT',
  components_ressources_subheader_text:
    'Kaikki tarvitsemasi resurssit terveelliseen elämään',
  components_ressources_span_text:
    'Ravitse hyvinvointiasi: Vapauta runsaasti resursseja kokonaisvaltaiseen terveyteen ja terveelliseen elämään, mukaan lukien asiantuntija-ohjeet kuntoilusta, ravitsemuksesta ja kestävistä elämäntavoista.',
  components_ressources_card_1_title: 'Motivaatio',
  components_ressources_card_1_text:
    'Lyhyitä motivaatiopurskeita kestäviin liikuntatottumuksiin.',
  components_ressources_card_2_title: 'Ruokavalio',
  components_ressources_card_2_text:
    'Älykkäitä ravitsemusvinkkejä terveempään ja vahvempaan.',
  components_ressources_link: 'Katso videoita',
  components_notes_and_google_title_1: 'Fitnes-harjoittelu',
  components_notes_and_google_title_2: 'Säännöllinen rutiini',
  components_notes_and_google_title_3: 'Ruokavalion ylläpito',
  components_review_google_text: 'Arvostele Googlessa',
  videos_content_section_title: 'HARJOITUKSET',
  videos_content_section_subheader_text: 'Meidän videot',
  text_men: 'Miehet',
  text_women: 'Naiset',
  videos_content_section_button_more: 'Lisää videoita',
  blog_section_title: 'BLOGI',
  blog_section_subheader_text: 'Artikkelit ja uutiset',
  blog_section_button_more: 'Lisää artikkeleita',
  components_recipes_section_title: 'REseptejä',
  components_recipes_subheader_text:
    'Terveellinen ruokavalio aktiiviseen elämääsi',
  components_recipes_span_text:
    'Ruoanlaitto elinvoimaa: Tutustu maukkaan Fitnessin maailmaan nopeilla ja ravintoainepakkaisilla resepteillämme.',
  components_recipes_card_1_title: 'Aloita päiväsi',
  components_recipes_card_1_text:
    'Aloita aamusi ravinteikkaisilla, proteiinipitoisilla aamiaisvaihtoehdoilla.',
  components_recipes_card_3_title: 'Tehokkaat lounaat',
  components_recipes_card_3_text:
    'Löydä herkullisia ja käteviä lounasreseptejä, jotka on suunniteltu aktiiviseen elämäntapaasi. Värikkäistä salaateista proteiinipitoisiin kääreisiin nämä reseptit pitävät sinut virkeänä ja keskittyneenä.',
  components_recipes_card_2_title: 'Illalliset palautumista varten',
  components_recipes_card_2_text:
    'Tutki erilaisia ​​vähärasvaisia ​​proteiineja, täysjyvätuotteita ja eloisia vihanneksia saadaksesi päiväsi tyydyttävän päätöksen.',
  components_recipes_link: 'Katso kaikki reseptit',
  components_footer_text:
    'Lisää voimaasi, sytytä intohimosi: kuntosalisi, muutoksesi.',
  components_footer_info: 'Tiedot',
  components_footer_registration_number: 'Rekisterinumero',
  components_footer_legal: 'Laillinen',
  components_footer_terms: 'Ehdot',
  components_footer_privacy: 'Tietosuojakäytäntö',
  button_load_more: 'Lataa lisää',
  page_men_videos_content_hero_text: 'Miesten treenit',
  page_videos_content_menu_all: 'Kaikki',
  page_videos_content_menu_biceps: 'Hauislihas',
  page_videos_content_menu_triceps: 'Triceps',
  page_videos_content_menu_back: 'Takaisin',
  page_videos_content_menu_chest: 'Rintakehä',
  page_videos_content_menu_legs: 'Jalat',
  page_videos_content_menu_shoulders: 'Hartiat',
  page_videos_content_menu_abdominals: 'Vatsalihakset',
  page_women_videos_content_hero_text: 'Naisten treenit',
  page_recipes_hero_text: 'Reseptit',
  page_recipes_section_title: 'Ravitsemus',
  page_recipes_subheader_text: 'Reseptit',
  page_articles_hero_text: 'Meidän blogi',
  page_articles_section_title: 'Blogi',
  page_articles_subheader_text: 'Artikkelit ja uutiset',
  enter_email_title: 'Anna sähköpostiosoitteesi jatkaaksesi',
  enter_email_placeholder_email: 'Sähköposti',
  enter_email_button_enter: 'Enter',
  components_login_success_text: 'Kirjautuminen onnistui!',
  components_card_link_video_content: 'Katso nyt',
  components_card_link_recipe: 'Katso Resepti',
  components_card_link_article: 'Katso artikkeli',
  france: 'Ranska',
  cyprus: 'Kypros',
};
