import { Wording } from '@/types';

export const PILATES_WORDING_FI: Wording = {
  components_header_videos_content: 'Harjoitukset',
  components_header_men: '',
  components_header_women: '',
  components_header_recipes: 'Reseptit',
  components_header_blog: 'Blogi',
  components_header_members: 'jäsenet',
  hero_text: 'Pidä kehosi joustavana ja vahvana',
  hero_button_text: 'Aloita Pilates-matkasi',
  components_ressources_section_title: 'RESURSSIT',
  components_ressources_subheader_text:
    'Kaikki tarvitsemasi resurssit harmoniseen elämäntapaan',
  components_ressources_span_text:
    'Avaa hyvinvoinnin salaisuudet: Sukella kokoelmaan Pilates-harjoituksia ja terveellisiä reseptejä luodaksesi tasapainoa elämäsi kaikilla osa-alueilla.',
  components_ressources_card_1_title: 'Pilates vinkkejä',
  components_ressources_card_1_text:
    'Käytännön neuvoja harjoituksen syventämiseen ja rutiinin parantamiseen.',
  components_ressources_card_2_title: 'Ruokavalio',
  components_ressources_card_2_text:
    'Älykkäitä ravitsemusvinkkejä terveempään ja vahvempaan.',
  components_ressources_link: 'Katso videoita',
  components_notes_and_google_title_1: 'Pilates',
  components_notes_and_google_title_2: 'Säännöllinen rutiini',
  components_notes_and_google_title_3: 'Ruokavalion ylläpito',
  components_review_google_text: 'Arvostele Googlessa',
  videos_content_section_title: 'HARJOITUKSET',
  videos_content_section_subheader_text: 'Meidän videot',
  text_men: '',
  text_women: '',
  videos_content_section_button_more: 'Lisää videoita',
  blog_section_title: 'BLOGI',
  blog_section_subheader_text: 'Artikkelit ja uutiset',
  blog_section_button_more: 'Lisää artikkeleita',
  components_recipes_section_title: 'REseptejä',
  components_recipes_subheader_text: 'Ravitse kehoasi ja mieltäsi',
  components_recipes_span_text:
    'Terveellisiä reseptejä Pilates-rutiinien tukemiseen ja hyvinvoinnin parantamiseen.',
  components_recipes_card_1_title: 'Energisoivat aamiaiset',
  components_recipes_card_1_text:
    'Herää eloisiin, energiaa lisääviin aterioihin.',
  components_recipes_card_3_title: 'Tehokkaat lounaat',
  components_recipes_card_3_text:
    'Löydä herkullisia ja käteviä lounasreseptejä, jotka on suunniteltu aktiiviseen elämäntapaasi. Värikkäistä salaateista proteiinipitoisiin kääreisiin nämä reseptit pitävät sinut virkeänä ja keskittyneenä.',
  components_recipes_card_2_title: 'Illalliset palautumista varten',
  components_recipes_card_2_text:
    'Tutki erilaisia ​​vähärasvaisia ​​proteiineja, täysjyvätuotteita ja eloisia vihanneksia saadaksesi päiväsi tyydyttävän päätöksen.',
  components_recipes_link: 'Katso kaikki reseptit',
  components_footer_text: 'Löydä tasapaino, hengitä syvään: Pilates, matkasi.',
  components_footer_info: 'Tiedot',
  components_footer_registration_number: 'Rekisterinumero',
  components_footer_legal: 'Laillinen',
  components_footer_terms: 'Ehdot',
  components_footer_privacy: 'Tietosuojakäytäntö',
  button_load_more: 'Lataa lisää',
  page_men_videos_content_hero_text: 'Pilates harjoitukset',
  page_recipes_hero_text: 'Reseptit',
  page_recipes_section_title: 'Ravitsemus',
  page_recipes_subheader_text: 'Reseptit',
  page_articles_hero_text: 'Meidän blogi',
  page_articles_section_title: 'Blogi',
  page_articles_subheader_text: 'Artikkelit ja uutiset',
  enter_email_title: 'Anna sähköpostiosoitteesi jatkaaksesi',
  enter_email_placeholder_email: 'Sähköposti',
  enter_email_button_enter: 'Enter',
  components_login_success_text: 'Kirjautuminen onnistui!',
  components_card_link_video_content: 'Katso nyt',
  components_card_link_recipe: 'Katso Resepti',
  components_card_link_article: 'Katso artikkeli',
  france: 'Ranska',
  cyprus: 'Kypros',
};
