import { Wording } from '@/types';

export const FITNESS_WORDING_PL: Wording = {
  components_header_videos_content: 'Treningi',
  components_header_men: 'Dla mężczyzn',
  components_header_women: 'Dla kobiet',
  components_header_recipes: 'Przepisy',
  components_header_blog: 'Bloga',
  components_header_members: 'Członkowie',
  hero_text: 'Utrzymuj swoje ciało w dobrej kondycji i sile',
  hero_button_text: 'Rozpocznij swoją podróż fitness',
  components_ressources_section_title: 'ZASOBY',
  components_ressources_subheader_text:
    'Wszystkie zasoby potrzebne do zdrowego trybu życia',
  components_ressources_span_text:
    'Dbaj o dobre samopoczucie: uwolnij bogactwo zasobów na rzecz holistycznego zdrowia i zdrowego trybu życia, w tym porady ekspertów dotyczące sprawności fizycznej, odżywiania i zrównoważonego stylu życia.',
  components_ressources_card_1_title: 'Motywacja',
  components_ressources_card_1_text:
    'Krótkie impulsy motywacji do trwałych nawyków ćwiczeń.',
  components_ressources_card_2_title: 'Dieta',
  components_ressources_card_2_text:
    'Inteligentne wskazówki żywieniowe, dzięki którym będziesz zdrowszy i silniejszy.',
  components_ressources_link: 'Obejrzyj filmy',
  components_notes_and_google_title_1: 'Trening fitness',
  components_notes_and_google_title_2: 'Regularna rutyna',
  components_notes_and_google_title_3: 'Utrzymanie diety',
  components_review_google_text: 'Recenzja w Google',
  videos_content_section_title: 'TRENINGI',
  videos_content_section_subheader_text: 'Nasze filmy',
  text_men: 'Mężczyźni',
  text_women: 'Kobiety',
  videos_content_section_button_more: 'Więcej filmów',
  blog_section_title: 'BLOG',
  blog_section_subheader_text: 'Artykuły i aktualności',
  blog_section_button_more: 'Więcej artykułów',
  components_recipes_section_title: 'PRZEPISY',
  components_recipes_subheader_text: 'Zdrowa dieta dla Twojego aktywnego życia',
  components_recipes_span_text:
    'Cooking Up Vitality: odkryj świat smacznego fitnessu dzięki naszym szybkim i bogatym w składniki odżywcze przepisom.',
  components_recipes_card_1_title: 'Rozpocznij swój dzień',
  components_recipes_card_1_text:
    'Zacznij dobrze poranek od śniadania bogatego w składniki odżywcze i białko.',
  components_recipes_card_3_title: 'Obiady pełne mocy',
  components_recipes_card_3_text:
    'Odkryj pyszne i wygodne przepisy na lunch zaprojektowane z myślą o Twoim aktywnym trybie życia. Od kolorowych sałatek po pełne białka wrapy – te przepisy dodadzą Ci energii i skupią.',
  components_recipes_card_2_title: 'Obiady na regenerację',
  components_recipes_card_2_text:
    'Odkryj różnorodne chude białko, produkty pełnoziarniste i żywe warzywa, aby zapewnić sobie satysfakcjonujący koniec dnia.',
  components_recipes_link: 'Zobacz wszystkie przepisy',
  components_footer_text:
    'Podnieś swoją siłę, rozpal swoją pasję: Twoja siłownia, Twoja transformacja.',
  components_footer_info: 'Informacje',
  components_footer_registration_number: 'Numer rejestracyjny',
  components_footer_legal: 'Prawny',
  components_footer_terms: 'Regulamin',
  components_footer_privacy: 'Polityka prywatności',
  button_load_more: 'Załaduj więcej',
  page_men_videos_content_hero_text: 'Treningi mężczyzn',
  page_videos_content_menu_all: 'Wszystko',
  page_videos_content_menu_biceps: 'Biceps',
  page_videos_content_menu_triceps: 'Triceps',
  page_videos_content_menu_back: 'Z powrotem',
  page_videos_content_menu_chest: 'Klatka piersiowa',
  page_videos_content_menu_legs: 'Nogi',
  page_videos_content_menu_shoulders: 'Plecy',
  page_videos_content_menu_abdominals: 'Brzuch',
  page_women_videos_content_hero_text: 'Treningi kobiet',
  page_recipes_hero_text: 'Przepisy',
  page_recipes_section_title: 'Odżywianie',
  page_recipes_subheader_text: 'Przepisy',
  page_articles_hero_text: 'Nasz blog',
  page_articles_section_title: 'Bloga',
  page_articles_subheader_text: 'Artykuły i aktualności',
  enter_email_title: 'Aby kontynuować, wprowadź swój adres e-mail',
  enter_email_placeholder_email: 'E-mail',
  enter_email_button_enter: 'Wchodzić',
  components_login_success_text: 'Zaloguj się pomyślnie!',
  components_card_link_video_content: 'Obejrzyj teraz',
  components_card_link_recipe: 'Zobacz przepis',
  components_card_link_article: 'Zobacz artykuł',
  france: 'Francja',
  cyprus: 'Cypr',
};
