import { Wording } from '@/types';

export const FITNESS_WORDING_FR: Wording = {
  components_header_videos_content: 'Entraînements',
  components_header_men: 'Pour les hommes',
  components_header_women: 'Pour les femmes',
  components_header_recipes: 'Recettes',
  components_header_blog: 'Blog',
  components_header_members: 'Membres',
  hero_text: 'Gardez votre corps en forme et fort',
  hero_button_text: 'Commencez votre parcours de remise en forme',
  components_ressources_section_title: 'RESSOURCES',
  components_ressources_subheader_text:
    'Toutes les ressources dont vous avez besoin pour une vie saine',
  components_ressources_span_text:
    "Nourrissez votre bien-être : libérez une richesse de ressources pour une santé holistique et une vie saine, y compris des conseils d'experts sur la forme physique, la nutrition et les modes de vie durables.",
  components_ressources_card_1_title: 'Motivation',
  components_ressources_card_1_text:
    "De courtes bouffées de motivation pour des habitudes d'exercice durables.",
  components_ressources_card_2_title: 'Régime',
  components_ressources_card_2_text:
    'Conseils nutritionnels intelligents pour être en meilleure santé et plus fort.',
  components_ressources_link: 'Regarder des vidéos',
  components_notes_and_google_title_1: 'Entraînement physique',
  components_notes_and_google_title_2: 'Routine régulière',
  components_notes_and_google_title_3: 'Entretien du régime',
  components_review_google_text: 'Avis sur Google',
  videos_content_section_title: 'ENTRAÎNEMENTS',
  videos_content_section_subheader_text: 'Nos vidéos',
  text_men: 'Hommes',
  text_women: 'Femmes',
  videos_content_section_button_more: 'Plus de vidéos',
  blog_section_title: 'BLOG',
  blog_section_subheader_text: 'Articles et actualités',
  blog_section_button_more: "Plus d'articles",
  components_recipes_section_title: 'RECETTES',
  components_recipes_subheader_text:
    'Une alimentation saine pour votre vie active',
  components_recipes_span_text:
    'Cuisinez de la vitalité : explorez un monde de remise en forme savoureuse avec nos recettes rapides et riches en nutriments.',
  components_recipes_card_1_title: 'Commencez votre journée',
  components_recipes_card_1_text:
    'Commencez votre journée du bon pied avec des options de petit-déjeuner riches en nutriments et en protéines.',
  components_recipes_card_3_title: 'Des déjeuners riches en énergie',
  components_recipes_card_3_text:
    "Découvrez des recettes de déjeuner délicieuses et pratiques conçues pour votre style de vie actif. Des salades colorées aux wraps riches en protéines, ces recettes vous permettent de rester concentré et plein d'énergie.",
  components_recipes_card_2_title: 'Dîners de récupération',
  components_recipes_card_2_text:
    'Explorez une variété de protéines maigres, de grains entiers et de légumes vibrants pour une fin de journée satisfaisante.',
  components_recipes_link: 'Voir toutes les recettes',
  components_footer_text:
    'Élevez votre force, enflammez votre passion : votre salle de sport, votre transformation.',
  components_footer_info: 'Informations',
  components_footer_registration_number: "Numéro d'enregistrement",
  components_footer_legal: 'Légal',
  components_footer_terms: 'Termes et conditions',
  components_footer_privacy: 'politique de confidentialité',
  button_load_more: 'Charger plus',
  page_men_videos_content_hero_text: 'Entraînements pour hommes',
  page_videos_content_menu_all: 'Tous',
  page_videos_content_menu_biceps: 'Biceps',
  page_videos_content_menu_triceps: 'Triceps',
  page_videos_content_menu_back: 'Dos',
  page_videos_content_menu_chest: 'Poitrine',
  page_videos_content_menu_legs: 'Jambes',
  page_videos_content_menu_shoulders: 'Épaules',
  page_videos_content_menu_abdominals: 'Abdominaux',
  page_women_videos_content_hero_text: 'Entraînements pour femmes',
  page_recipes_hero_text: 'Recettes',
  page_recipes_section_title: 'Nutrition',
  page_recipes_subheader_text: 'Recettes',
  page_articles_hero_text: 'Notre blog',
  page_articles_section_title: 'Blog',
  page_articles_subheader_text: 'Articles et actualités',
  enter_email_title: 'Veuillez entrer votre email pour continuer',
  enter_email_placeholder_email: 'E-mail',
  enter_email_button_enter: 'Entrer',
  components_login_success_text: 'Connexion réussie !',
  components_card_link_video_content: 'Regarder maintenant',
  components_card_link_recipe: 'Voir la recette',
  components_card_link_article: "Voir l'article",
  france: 'France',
  cyprus: 'Chypre',
};
