import { Wording } from '@/types';

export const FITNESS_WORDING_BG: Wording = {
  components_header_videos_content: 'Тренировки',
  components_header_men: 'За мъжете',
  components_header_women: 'За жени',
  components_header_recipes: 'Рецепти',
  components_header_blog: 'Блог',
  components_header_members: 'Членове',
  hero_text: 'Поддържайте тялото си във форма и силно',
  hero_button_text: 'Започнете вашето фитнес пътуване',
  components_ressources_section_title: 'РЕСУРСИ',
  components_ressources_subheader_text:
    'Всички ресурси, от които се нуждаете за здравословен живот',
  components_ressources_span_text:
    'Подхранвайте благосъстоянието си: Отприщване на изобилие от ресурси за холистично здраве и пълноценен живот, включително експертни насоки за фитнес, хранене и устойчив начин на живот.',
  components_ressources_card_1_title: 'Мотивация',
  components_ressources_card_1_text:
    'Кратки изблици на мотивация за трайни навици за упражнения.',
  components_ressources_card_2_title: 'диета',
  components_ressources_card_2_text:
    'Интелигентни съвети за хранене за по-здрави и по-силни.',
  components_ressources_link: 'Гледайте видеоклипове',
  components_notes_and_google_title_1: 'Фитнес обучение',
  components_notes_and_google_title_2: 'Редовна рутина',
  components_notes_and_google_title_3: 'Поддържане на диета',
  components_review_google_text: 'Преглед в Google',
  videos_content_section_title: 'ТРЕНИРОВКИ',
  videos_content_section_subheader_text: 'Нашите видеоклипове',
  text_men: 'мъже',
  text_women: 'жени',
  videos_content_section_button_more: 'Още видеоклипове',
  blog_section_title: 'БЛОГ',
  blog_section_subheader_text: 'Статии и новини',
  blog_section_button_more: 'Още статии',
  components_recipes_section_title: 'РЕЦЕПТИ',
  components_recipes_subheader_text:
    'Здравословна диета за вашия активен живот',
  components_recipes_span_text:
    'Готвене на жизненост: Изследвайте свят на ароматен фитнес с нашите бързи и пълни с хранителни вещества рецепти.',
  components_recipes_card_1_title: 'Ускорете деня си',
  components_recipes_card_1_text:
    'Започнете сутринта си правилно с богати на хранителни вещества и протеини опции за закуска.',
  components_recipes_card_3_title: 'Пакетирани обяди',
  components_recipes_card_3_text:
    'Открийте вкусни и удобни рецепти за обяд, предназначени за вашия активен начин на живот. От цветни салати до опаковани с протеини опаковки, тези рецепти ви държат заредени и фокусирани.',
  components_recipes_card_2_title: 'Вечери за възстановяване',
  components_recipes_card_2_text:
    'Разгледайте разнообразие от постни протеини, пълнозърнести храни и живи зеленчуци за задоволителен завършек на деня ви.',
  components_recipes_link: 'Вижте всички рецепти',
  components_footer_text:
    'Увеличете силата си, запалете страстта си: Вашата фитнес зала, Вашата трансформация.',
  components_footer_info: 'Информация',
  components_footer_registration_number: 'Регистрационен номер',
  components_footer_legal: 'Законни',
  components_footer_terms: 'Правила и условия',
  components_footer_privacy: 'Политика за поверителност',
  button_load_more: 'Зареди още',
  page_men_videos_content_hero_text: 'Тренировки за мъже',
  page_videos_content_menu_all: 'Всички',
  page_videos_content_menu_biceps: 'Бицепс',
  page_videos_content_menu_triceps: 'Трицепс',
  page_videos_content_menu_back: 'Назад',
  page_videos_content_menu_chest: 'Гръден кош',
  page_videos_content_menu_legs: 'Крака',
  page_videos_content_menu_shoulders: 'Рамене',
  page_videos_content_menu_abdominals: 'Коремни',
  page_women_videos_content_hero_text: 'Женски тренировки',
  page_recipes_hero_text: 'Рецепти',
  page_recipes_section_title: 'Хранене',
  page_recipes_subheader_text: 'Рецепти',
  page_articles_hero_text: 'Нашият блог',
  page_articles_section_title: 'Блог',
  page_articles_subheader_text: 'Статии и новини',
  enter_email_title: 'Моля, въведете вашия имейл, за да продължите',
  enter_email_placeholder_email: 'Имейл',
  enter_email_button_enter: 'Въведете',
  components_login_success_text: 'Успешно влизане!',
  components_card_link_video_content: 'Гледайте сега',
  components_card_link_recipe: 'Вижте рецепта',
  components_card_link_article: 'Вижте статията',
  france: 'Франция',
  cyprus: 'Кипър',
};
