import { Wording } from '@/types';

export const YOGA_WORDING_LT: Wording = {
  components_header_videos_content: 'Treniruotės',
  components_header_men: '',
  components_header_women: '',
  components_header_recipes: 'Receptai',
  components_header_blog: 'Dienoraštis',
  components_header_members: 'nariai',
  hero_text: 'Laikykite savo kūną lankstų ir tvirtą',
  hero_button_text: 'Pradėkite savo jogos kelionę',
  components_ressources_section_title: 'IŠTEKLIAI',
  components_ressources_subheader_text:
    'Visi ištekliai, kurių jums reikia harmoningam gyvenimo būdui',
  components_ressources_span_text:
    'Atraskite sveikatingumo paslaptis: pasinerkite į jogos pozų, sąmoningų meditacijų ir naudingų receptų rinkinį, kad sukurtumėte pusiausvyrą visose savo gyvenimo srityse.',
  components_ressources_card_1_title: 'Jogos patarimai',
  components_ressources_card_1_text:
    'Praktiniai patarimai, kaip pagilinti savo praktiką ir tobulinti pozas.',
  components_ressources_card_2_title: 'Dieta',
  components_ressources_card_2_text:
    'Protingos mitybos patarimai, kad būtumėte sveikesni ir stipresni.',
  components_ressources_link: 'Žiūrėti vaizdo įrašus',
  components_notes_and_google_title_1: 'Jogos treniruotės',
  components_notes_and_google_title_2: 'Reguliari rutina',
  components_notes_and_google_title_3: 'Dietos palaikymas',
  components_review_google_text: 'Peržiūrėkite „Google“.',
  videos_content_section_title: 'TRENIRUOTĖS',
  videos_content_section_subheader_text: 'Mūsų vaizdo įrašai',
  text_men: '',
  text_women: '',
  videos_content_section_button_more: 'Daugiau vaizdo įrašų',
  blog_section_title: 'BLOGAS',
  blog_section_subheader_text: 'Straipsniai ir naujienos',
  blog_section_button_more: 'Daugiau straipsnių',
  components_recipes_section_title: 'RECEPTAI',
  components_recipes_subheader_text: 'Maitinkite savo kūną ir protą',
  components_recipes_span_text:
    'Naudingi receptai, padėsiantys užsiimti joga ir pagerinti jūsų savijautą.',
  components_recipes_card_1_title: 'Energiją suteikiantys pusryčiai',
  components_recipes_card_1_text:
    'Pabuskite valgydami energingus, energiją suteikiančius patiekalus.',
  components_recipes_card_3_title: 'Energingi pietūs',
  components_recipes_card_3_text:
    'Atraskite skanių ir patogių pietų receptų, skirtų jūsų aktyviam gyvenimo būdui. Nuo spalvingų salotų iki baltymų kupinų įvyniojimų – šie receptai leis jums pailsėti ir sutelkti dėmesį.',
  components_recipes_card_2_title: 'Vakarienės atsigavimui',
  components_recipes_card_2_text:
    'Išbandykite įvairius liesus baltymus, nesmulkintus grūdus ir energingas daržoves, kad dieną užbaigtumėte sočiai.',
  components_recipes_link: 'Žiūrėti visus receptus',
  components_footer_text:
    'Raskite pusiausvyrą, giliai kvėpuokite: jūsų joga, jūsų kelionė.',
  components_footer_info: 'Informacija',
  components_footer_registration_number: 'Registracijos numeris',
  components_footer_legal: 'Teisinė',
  components_footer_terms: 'Taisyklės ir sąlygos',
  components_footer_privacy: 'Privatumo politika',
  button_load_more: 'Įkelti daugiau',
  page_men_videos_content_hero_text: 'Jogos treniruotės',
  page_recipes_hero_text: 'Receptai',
  page_recipes_section_title: 'Mityba',
  page_recipes_subheader_text: 'Receptai',
  page_articles_hero_text: 'Mūsų tinklaraštis',
  page_articles_section_title: 'Dienoraštis',
  page_articles_subheader_text: 'Straipsniai ir naujienos',
  enter_email_title: 'Norėdami tęsti, įveskite savo el. pašto adresą',
  enter_email_placeholder_email: 'El. paštas',
  enter_email_button_enter: 'Įeikite',
  components_login_success_text: 'Prisijungimas sėkmingas!',
  components_card_link_video_content: 'Žiūrėti dabar',
  components_card_link_recipe: 'Žiūrėti receptą',
  components_card_link_article: 'Žiūrėti straipsnį',
  france: 'Prancūzija',
  cyprus: 'Kipras',
};
