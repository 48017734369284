import { Wording } from '@/types';

export const PILATES_WORDING_LV: Wording = {
  components_header_videos_content: 'Treniņi',
  components_header_men: '',
  components_header_women: '',
  components_header_recipes: 'Receptes',
  components_header_blog: 'Emuārs',
  components_header_members: 'Biedri',
  hero_text: 'Saglabājiet savu ķermeni elastīgu un stipru',
  hero_button_text: 'Sāciet savu Pilates ceļojumu',
  components_ressources_section_title: 'RESURSI',
  components_ressources_subheader_text:
    'Visi resursi, kas nepieciešami harmoniskam dzīvesveidam',
  components_ressources_span_text:
    'Atklājiet labsajūtas noslēpumus: iedziļinieties pilates vingrinājumu un veselīgo recepšu kolekcijā, lai radītu līdzsvaru ikvienā savas dzīves jomā.',
  components_ressources_card_1_title: 'Pilates padomi',
  components_ressources_card_1_text:
    'Praktiski padomi, lai padziļinātu praksi un pilnveidotu savu rutīnu.',
  components_ressources_card_2_title: 'Diēta',
  components_ressources_card_2_text:
    'Gudra uztura padomi veselīgākam un stiprākam.',
  components_ressources_link: 'Skatieties video',
  components_notes_and_google_title_1: 'Pilates',
  components_notes_and_google_title_2: 'Regulāra rutīna',
  components_notes_and_google_title_3: 'Diētas uzturēšana',
  components_review_google_text: 'Pārskats Google tīklā',
  videos_content_section_title: 'TRENIŅI',
  videos_content_section_subheader_text: 'Mūsu video',
  text_men: '',
  text_women: '',
  videos_content_section_button_more: 'Vairāk video',
  blog_section_title: 'BLOGS',
  blog_section_subheader_text: 'Raksti un ziņas',
  blog_section_button_more: 'Vairāk rakstu',
  components_recipes_section_title: 'RECEPTES',
  components_recipes_subheader_text: 'Barojiet savu ķermeni un prātu',
  components_recipes_span_text:
    'Veselīgas receptes, lai atbalstītu jūsu pilates rutīnas un uzlabotu jūsu labsajūtu.',
  components_recipes_card_1_title: 'Enerģiskas brokastis',
  components_recipes_card_1_text:
    'Mostieties ar dinamiskām, enerģiju vairojošām maltītēm.',
  components_recipes_card_3_title: 'Jaudīgas pusdienas',
  components_recipes_card_3_text:
    'Atklājiet gardas un ērtas pusdienu receptes, kas paredzētas jūsu aktīvajam dzīvesveidam. No krāsainiem salātiem līdz olbaltumvielām bagātiem iesaiņojumiem – šīs receptes saglabās jūsu enerģiju un koncentrēšanos.',
  components_recipes_card_2_title: 'Vakariņas atveseļošanai',
  components_recipes_card_2_text:
    'Izpētiet dažādas liesās olbaltumvielas, veselus graudus un dinamiskus dārzeņus, lai nobeigtu savu dienu.',
  components_recipes_link: 'Skatīt visas receptes',
  components_footer_text:
    'Atrodiet līdzsvaru, elpojiet dziļi: jūsu pilates, jūsu ceļojums.',
  components_footer_info: 'Informācija',
  components_footer_registration_number: 'Reģistrācijas numurs',
  components_footer_legal: 'Juridisks',
  components_footer_terms: 'Noteikumi un nosacījumi',
  components_footer_privacy: 'Privātuma politika',
  button_load_more: 'Ielādēt vairāk',
  page_men_videos_content_hero_text: 'Pilates treniņi',
  page_recipes_hero_text: 'Receptes',
  page_recipes_section_title: 'Uzturs',
  page_recipes_subheader_text: 'Receptes',
  page_articles_hero_text: 'Mūsu emuārs',
  page_articles_section_title: 'Emuārs',
  page_articles_subheader_text: 'Raksti un ziņas',
  enter_email_title: 'Lūdzu, ievadiet savu e-pastu, lai turpinātu',
  enter_email_placeholder_email: 'E-pasts',
  enter_email_button_enter: 'Ievadiet',
  components_login_success_text: 'Pieteikšanās veiksmīga!',
  components_card_link_video_content: 'Skatieties tūlīt',
  components_card_link_recipe: 'Skatīt recepti',
  components_card_link_article: 'Skatīt rakstu',
  france: 'Francija',
  cyprus: 'Kipra',
};
