import { Wording } from '@/types';

export const YOGA_WORDING_BG: Wording = {
  components_header_videos_content: 'Тренировки',
  components_header_men: '',
  components_header_women: '',
  components_header_recipes: 'Рецепти',
  components_header_blog: 'Блог',
  components_header_members: 'Членове',
  hero_text: 'Поддържайте тялото си гъвкаво и силно',
  hero_button_text: 'Започнете вашето йога пътешествие',
  components_ressources_section_title: 'РЕСУРСИ',
  components_ressources_subheader_text:
    'Всички ресурси, от които се нуждаете за хармоничен начин на живот',
  components_ressources_span_text:
    'Отключете тайните за уелнес: Потопете се в колекция от йога пози, медитации и здравословни рецепти, за да създадете баланс във всеки аспект от живота си.',
  components_ressources_card_1_title: 'Съвети за йога',
  components_ressources_card_1_text:
    'Практически съвети за задълбочаване на практиката и усъвършенстване на вашите пози.',
  components_ressources_card_2_title: 'диета',
  components_ressources_card_2_text:
    'Интелигентни съвети за хранене за по-здрави и по-силни.',
  components_ressources_link: 'Гледайте видеоклипове',
  components_notes_and_google_title_1: 'Йога тренировки',
  components_notes_and_google_title_2: 'Редовна рутина',
  components_notes_and_google_title_3: 'Поддържане на диета',
  components_review_google_text: 'Преглед в Google',
  videos_content_section_title: 'ТРЕНИРОВКИ',
  videos_content_section_subheader_text: 'Нашите видеоклипове',
  text_men: '',
  text_women: '',
  videos_content_section_button_more: 'Още видеоклипове',
  blog_section_title: 'БЛОГ',
  blog_section_subheader_text: 'Статии и новини',
  blog_section_button_more: 'Още статии',
  components_recipes_section_title: 'РЕЦЕПТИ',
  components_recipes_subheader_text: 'Подхранвайте тялото и ума си',
  components_recipes_span_text:
    'Здравословни рецепти в подкрепа на йога практиката ви и подобряване на благосъстоянието ви.',
  components_recipes_card_1_title: 'Енергизиращи закуски',
  components_recipes_card_1_text:
    'Събудете се с жизнени, повишаващи енергията ястия.',
  components_recipes_card_3_title: 'Пакетирани обяди',
  components_recipes_card_3_text:
    'Открийте вкусни и удобни рецепти за обяд, предназначени за вашия активен начин на живот. От цветни салати до опаковани с протеини опаковки, тези рецепти ви държат заредени и фокусирани.',
  components_recipes_card_2_title: 'Вечери за възстановяване',
  components_recipes_card_2_text:
    'Разгледайте разнообразие от постни протеини, пълнозърнести храни и живи зеленчуци за задоволителен завършек на деня ви.',
  components_recipes_link: 'Вижте всички рецепти',
  components_footer_text:
    'Намерете баланс, дишайте дълбоко: вашата йога, вашето пътуване.',
  components_footer_info: 'Информация',
  components_footer_registration_number: 'Регистрационен номер',
  components_footer_legal: 'Законни',
  components_footer_terms: 'Правила и условия',
  components_footer_privacy: 'Политика за поверителност',
  button_load_more: 'Зареди още',
  page_men_videos_content_hero_text: 'Йога тренировки',
  page_recipes_hero_text: 'Рецепти',
  page_recipes_section_title: 'Хранене',
  page_recipes_subheader_text: 'Рецепти',
  page_articles_hero_text: 'Нашият блог',
  page_articles_section_title: 'Блог',
  page_articles_subheader_text: 'Статии и новини',
  enter_email_title: 'Моля, въведете вашия имейл, за да продължите',
  enter_email_placeholder_email: 'Имейл',
  enter_email_button_enter: 'Въведете',
  components_login_success_text: 'Успешно влизане!',
  components_card_link_video_content: 'Гледайте сега',
  components_card_link_recipe: 'Вижте рецепта',
  components_card_link_article: 'Вижте статията',
  france: 'Франция',
  cyprus: 'Кипър',
};
