import { Wording } from '@/types';

export const YOGA_WORDING_RS: Wording = {
  components_header_videos_content: 'Vežbe',
  components_header_men: '',
  components_header_women: '',
  components_header_recipes: 'Recepti',
  components_header_blog: 'Blog',
  components_header_members: 'Članovi',
  hero_text: 'Održavajte svoje telo fleksibilnim i snažnim',
  hero_button_text: 'Započnite svoje joga putovanje',
  components_ressources_section_title: 'RESURSI',
  components_ressources_subheader_text:
    'Svi resursi koji su vam potrebni za harmoničan život',
  components_ressources_span_text:
    'Brinite o sebi: Istražite raznovrsne resurse za sveobuhvatno zdravlje i kvalitetan život, uz vrhunske savete o jogi, ishrani i održivim životnim navikama.',
  components_ressources_card_1_title: 'Joga saveti',
  components_ressources_card_1_text:
    'Praktični saveti za usavršavanje poza i formiranje trajnih navika.',
  components_ressources_card_2_title: 'Ishrana',
  components_ressources_card_2_text:
    'Predlozi za ishranu koji vode ka zdravijem i snažnijem životu.',
  components_ressources_link: 'Gledajte video zapise',
  components_notes_and_google_title_1: 'Joga vežbe',
  components_notes_and_google_title_2: 'Redovna rutina',
  components_notes_and_google_title_3: 'Održavanje dijete',
  components_review_google_text: 'Google recenzije',
  videos_content_section_title: 'VEŽBE',
  videos_content_section_subheader_text: 'Naši video snimci',
  text_men: '',
  text_women: '',
  videos_content_section_button_more: 'Više videa',
  blog_section_title: 'BLOG',
  blog_section_subheader_text: 'Članci i vesti',
  blog_section_button_more: 'Više članaka',
  components_recipes_section_title: 'RECEPTI',
  components_recipes_subheader_text: 'Zdrava ishrana za balansiran život',
  components_recipes_span_text:
    'Recepti koji podržavaju vaš životni stil i praktikovanje joge.',
  components_recipes_card_1_title: 'Započnite svoj dan',
  components_recipes_card_1_text:
    'Započnite svoja jutra bogatim i hranljivim opcijama za doručak.',
  components_recipes_card_3_title: 'Ručkovi za snagu',
  components_recipes_card_3_text:
    'Otkrijte ukusne i praktične recepte za ručak dizajnirane za vaš aktivan životni stil. Od šarenih salata do tortilja prepunih proteina, ovi recepti će lansirati vašu energiju u svemir!',
  components_recipes_card_2_title: 'Večere za oporavak',
  components_recipes_card_2_text:
    'Istražite razne nemasne proteine, integralne žitarice i živopisno povrće za zadovoljavajući završetak dana.',
  components_recipes_link: 'Pogledajte sve recepte',
  components_footer_text:
    'Pronađite ravnotežu, dišite duboko: Vaša joga, vaše putovanje.',
  components_footer_info: 'Info',
  components_footer_registration_number: 'Registracioni broj',
  components_footer_legal: 'Pravna',
  components_footer_terms: 'Uslovi i odredbe',
  components_footer_privacy: 'Politika privatnosti',
  button_load_more: 'Učitaj još',
  page_men_videos_content_hero_text: 'Joga vežbe',
  page_recipes_hero_text: 'Recepti',
  page_recipes_section_title: 'Ishrana',
  page_recipes_subheader_text: 'Recepti',
  page_articles_hero_text: 'Naš blog',
  page_articles_section_title: 'Blog',
  page_articles_subheader_text: 'Članci i vesti',
  enter_email_title: 'Unesite svoju e-mail adresu da biste nastavili',
  enter_email_placeholder_email: 'Email',
  enter_email_button_enter: 'Unesi',
  components_login_success_text: 'Пријава је успела!',
  components_card_link_video_content: 'Pusti video',
  components_card_link_recipe: 'Vidi recept',
  components_card_link_article: 'Vidi članak',
  france: 'Француска',
  cyprus: 'Кипар',
};
