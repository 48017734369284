import { Wording } from '@/types';

export const YOGA_WORDING_EE: Wording = {
  components_header_videos_content: 'Treeningud',
  components_header_men: '',
  components_header_women: '',
  components_header_recipes: 'Retseptid',
  components_header_blog: 'Blogi',
  components_header_members: 'liikmed',
  hero_text: 'Hoidke oma keha painduvana ja tugevana',
  hero_button_text: 'Alustage oma joogareisi',
  components_ressources_section_title: 'VAHENDID',
  components_ressources_subheader_text:
    'Kõik ressursid, mida vajate harmooniliseks elustiiliks',
  components_ressources_span_text:
    'Avastage heaolu saladused: sukelduge joogapooside, teadlike meditatsioonide ja tervislike retseptide kogusse, et luua tasakaal oma elu igas aspektis.',
  components_ressources_card_1_title: 'Jooga nõuanded',
  components_ressources_card_1_text:
    'Praktilised nõuanded praktika süvendamiseks ja pooside täiustamiseks.',
  components_ressources_card_2_title: 'Dieet',
  components_ressources_card_2_text:
    'Nutikad toitumisnõuanded tervislikumaks ja tugevamaks.',
  components_ressources_link: 'Vaata videoid',
  components_notes_and_google_title_1: 'Jooga treeningud',
  components_notes_and_google_title_2: 'Regulaarne rutiin',
  components_notes_and_google_title_3: 'Dieedi säilitamine',
  components_review_google_text: "Arvustage Google'is",
  videos_content_section_title: 'TREENINGUD',
  videos_content_section_subheader_text: 'Meie videod',
  text_men: '',
  text_women: '',
  videos_content_section_button_more: 'Rohkem videoid',
  blog_section_title: 'BLOGI',
  blog_section_subheader_text: 'Artiklid ja uudised',
  blog_section_button_more: 'Veel artikleid',
  components_recipes_section_title: 'RETSEPTID',
  components_recipes_subheader_text: 'Toitke oma keha ja vaimu',
  components_recipes_span_text:
    'Tervislikud retseptid teie joogapraktika toetamiseks ja heaolu parandamiseks.',
  components_recipes_card_1_title: 'Energiseerivad hommikusöögid',
  components_recipes_card_1_text:
    'Ärka üles erksate ja energiat suurendavate einetega.',
  components_recipes_card_3_title: 'Jõulised lõunasöögid',
  components_recipes_card_3_text:
    'Avastage maitsvaid ja mugavaid lõunasöögi retsepte, mis on loodud teie aktiivse elustiili jaoks. Alates värvilistest salatitest kuni valgurikaste wrappideni – need retseptid hoiavad teid energilisena ja keskendununa.',
  components_recipes_card_2_title: 'Õhtusöögid taastumiseks',
  components_recipes_card_2_text:
    'Avastage erinevaid lahja valke, täisteratooteid ja erksaid köögivilju, et oma päeva rahuldav lõpetada.',
  components_recipes_link: 'Vaadake kõiki retsepte',
  components_footer_text:
    'Leidke tasakaal, hingake sügavalt: teie jooga, teie teekond.',
  components_footer_info: 'Info',
  components_footer_registration_number: 'Registreerimisnumber',
  components_footer_legal: 'Juriidiline',
  components_footer_terms: 'Tingimused',
  components_footer_privacy: 'Privaatsuspoliitika',
  button_load_more: 'Laadige rohkem',
  page_men_videos_content_hero_text: 'Jooga treeningud',
  page_recipes_hero_text: 'Retseptid',
  page_recipes_section_title: 'Toitumine',
  page_recipes_subheader_text: 'Retseptid',
  page_articles_hero_text: 'Meie ajaveeb',
  page_articles_section_title: 'Blogi',
  page_articles_subheader_text: 'Artiklid ja uudised',
  enter_email_title: 'Jätkamiseks sisestage oma e-posti aadress',
  enter_email_placeholder_email: 'Meil',
  enter_email_button_enter: 'Sisestage',
  components_login_success_text: 'Sisselogimine õnnestus!',
  components_card_link_video_content: 'Vaadake kohe',
  components_card_link_recipe: 'Vaata retsepti',
  components_card_link_article: 'Vaata artiklit',
  france: 'Prantsusmaa',
  cyprus: 'Küpros',
};
