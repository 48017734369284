import { Wording } from '@/types';

export const FITNESS_WORDING_LT: Wording = {
  components_header_videos_content: 'Treniruotės',
  components_header_men: 'Vyrams',
  components_header_women: 'Moterims',
  components_header_recipes: 'Receptai',
  components_header_blog: 'Dienoraštis',
  components_header_members: 'nariai',
  hero_text: 'Laikykite savo kūną stangrų ir tvirtą',
  hero_button_text: 'Pradėkite savo kūno rengybos kelionę',
  components_ressources_section_title: 'IŠTEKLIAI',
  components_ressources_subheader_text:
    'Visi ištekliai, kurių jums reikia sveikai gyvensenai',
  components_ressources_span_text:
    'Puoselėkite savo gerovę: išlaisvinkite daugybę išteklių holistinei sveikatai ir sveikam gyvenimui, įskaitant ekspertų rekomendacijas dėl kūno rengybos, mitybos ir tvaraus gyvenimo būdo.',
  components_ressources_card_1_title: 'Motyvacija',
  components_ressources_card_1_text:
    'Trumpi motyvacijos pliūpsniai ilgalaikiams mankštos įpročiams.',
  components_ressources_card_2_title: 'Dieta',
  components_ressources_card_2_text:
    'Protingos mitybos patarimai, kad būtumėte sveikesni ir stipresni.',
  components_ressources_link: 'Žiūrėti vaizdo įrašus',
  components_notes_and_google_title_1: 'Fitneso treniruotės',
  components_notes_and_google_title_2: 'Reguliari rutina',
  components_notes_and_google_title_3: 'Dietos palaikymas',
  components_review_google_text: 'Peržiūrėkite „Google“.',
  videos_content_section_title: 'TRENIRUOTĖS',
  videos_content_section_subheader_text: 'Mūsų vaizdo įrašai',
  text_men: 'Vyrai',
  text_women: 'Moterys',
  videos_content_section_button_more: 'Daugiau vaizdo įrašų',
  blog_section_title: 'BLOGAS',
  blog_section_subheader_text: 'Straipsniai ir naujienos',
  blog_section_button_more: 'Daugiau straipsnių',
  components_recipes_section_title: 'RECEPTAI',
  components_recipes_subheader_text: 'Sveika mityba jūsų aktyviam gyvenimui',
  components_recipes_span_text:
    'Gaminame gyvybingumą: naršykite skoningo kūno rengybos pasaulį pagal mūsų greitus ir maistinių medžiagų turinčius receptus.',
  components_recipes_card_1_title: 'Pradėkite savo dieną',
  components_recipes_card_1_text:
    'Rytą pradėkite nuo maistingų, baltymų turinčių pusryčių.',
  components_recipes_card_3_title: 'Energingi pietūs',
  components_recipes_card_3_text:
    'Atraskite skanių ir patogių pietų receptų, skirtų jūsų aktyviam gyvenimo būdui. Nuo spalvingų salotų iki baltymų kupinų įvyniojimų – šie receptai leis jums pailsėti ir sutelkti dėmesį.',
  components_recipes_card_2_title: 'Vakarienės atsigavimui',
  components_recipes_card_2_text:
    'Išbandykite įvairius liesus baltymus, nesmulkintus grūdus ir energingas daržoves, kad dieną užbaigtumėte sočiai.',
  components_recipes_link: 'Žiūrėti visus receptus',
  components_footer_text:
    'Padidinkite savo jėgas, kurstykite aistrą: jūsų sporto salė, jūsų transformacija.',
  components_footer_info: 'Informacija',
  components_footer_registration_number: 'Registracijos numeris',
  components_footer_legal: 'Teisinė',
  components_footer_terms: 'Taisyklės ir sąlygos',
  components_footer_privacy: 'Privatumo politika',
  button_load_more: 'Įkelti daugiau',
  page_men_videos_content_hero_text: 'Vyrų treniruotės',
  page_videos_content_menu_all: 'Visi',
  page_videos_content_menu_biceps: 'Bicepsas',
  page_videos_content_menu_triceps: 'Tricepsas',
  page_videos_content_menu_back: 'Atgal',
  page_videos_content_menu_chest: 'Krūtinė',
  page_videos_content_menu_legs: 'Kojos',
  page_videos_content_menu_shoulders: 'Pečiai',
  page_videos_content_menu_abdominals: 'Pilvo raumenys',
  page_women_videos_content_hero_text: 'Moterų treniruotės',
  page_recipes_hero_text: 'Receptai',
  page_recipes_section_title: 'Mityba',
  page_recipes_subheader_text: 'Receptai',
  page_articles_hero_text: 'Mūsų tinklaraštis',
  page_articles_section_title: 'Dienoraštis',
  page_articles_subheader_text: 'Straipsniai ir naujienos',
  enter_email_title: 'Norėdami tęsti, įveskite savo el. pašto adresą',
  enter_email_placeholder_email: 'El. paštas',
  enter_email_button_enter: 'Įeikite',
  components_login_success_text: 'Prisijungimas sėkmingas!',
  components_card_link_video_content: 'Žiūrėti dabar',
  components_card_link_recipe: 'Žiūrėti receptą',
  components_card_link_article: 'Žiūrėti straipsnį',
  france: 'Prancūzija',
  cyprus: 'Kipras',
};
