import { LanguageOptions, ServiceType, Wording } from '@/types';

import { FITNESS_WORDING_BG } from './fitness/FITNESS_WORDING_BG';
import { FITNESS_WORDING_EE } from './fitness/FITNESS_WORDING_EE';
import { FITNESS_WORDING_EN } from './fitness/FITNESS_WORDING_EN';
import { FITNESS_WORDING_FI } from './fitness/FITNESS_WORDING_FI';
import { FITNESS_WORDING_FR } from './fitness/FITNESS_WORDING_FR';
import { FITNESS_WORDING_LT } from './fitness/FITNESS_WORDING_LT';
import { FITNESS_WORDING_LV } from './fitness/FITNESS_WORDING_LV';
import { FITNESS_WORDING_PL } from './fitness/FITNESS_WORDING_PL';
import { FITNESS_WORDING_RS } from './fitness/FITNESS_WORDING_RS';
import { PILATES_WORDING_BG } from './pilates/PILATES_WORDING_BG';
import { PILATES_WORDING_EE } from './pilates/PILATES_WORDING_EE';
import { PILATES_WORDING_EN } from './pilates/PILATES_WORDING_EN';
import { PILATES_WORDING_FI } from './pilates/PILATES_WORDING_FI';
import { PILATES_WORDING_FR } from './pilates/PILATES_WORDING_FR';
import { PILATES_WORDING_LT } from './pilates/PILATES_WORDING_LT';
import { PILATES_WORDING_LV } from './pilates/PILATES_WORDING_LV';
import { PILATES_WORDING_PL } from './pilates/PILATES_WORDING_PL';
import { PILATES_WORDING_RS } from './pilates/PILATES_WORDING_RS';
import { YOGA_WORDING_BG } from './yoga/YOGA_WORDING_BG';
import { YOGA_WORDING_EE } from './yoga/YOGA_WORDING_EE';
import { YOGA_WORDING_EN } from './yoga/YOGA_WORDING_EN';
import { YOGA_WORDING_FI } from './yoga/YOGA_WORDING_FI';
import { YOGA_WORDING_FR } from './yoga/YOGA_WORDING_FR';
import { YOGA_WORDING_LT } from './yoga/YOGA_WORDING_LT';
import { YOGA_WORDING_LV } from './yoga/YOGA_WORDING_LV';
import { YOGA_WORDING_PL } from './yoga/YOGA_WORDING_PL';
import { YOGA_WORDING_RS } from './yoga/YOGA_WORDING_RS';

const WORDING: ServiceWordingMap = {
  fitness: {
    BG: FITNESS_WORDING_BG,
    EE: FITNESS_WORDING_EE,
    EN: FITNESS_WORDING_EN,
    FI: FITNESS_WORDING_FI,
    FR: FITNESS_WORDING_FR,
    LT: FITNESS_WORDING_LT,
    LV: FITNESS_WORDING_LV,
    PL: FITNESS_WORDING_PL,
    RS: FITNESS_WORDING_RS,
  },
  pilates: {
    BG: PILATES_WORDING_BG,
    EE: PILATES_WORDING_EE,
    EN: PILATES_WORDING_EN,
    FI: PILATES_WORDING_FI,
    FR: PILATES_WORDING_FR,
    LT: PILATES_WORDING_LT,
    LV: PILATES_WORDING_LV,
    PL: PILATES_WORDING_PL,
    RS: PILATES_WORDING_RS,
  },
  yoga: {
    BG: YOGA_WORDING_BG,
    EE: YOGA_WORDING_EE,
    EN: YOGA_WORDING_EN,
    FI: YOGA_WORDING_FI,
    FR: YOGA_WORDING_FR,
    LT: YOGA_WORDING_LT,
    LV: YOGA_WORDING_LV,
    PL: YOGA_WORDING_PL,
    RS: YOGA_WORDING_RS,
  },
} as const; // "as const" to make it an immutable object for TS

type ServiceWordingMap = {
  [service in ServiceType]: Partial<Record<LanguageOptions, Wording>>;
};

export default WORDING;
