import { Wording } from '@/types';

export const PILATES_WORDING_PL: Wording = {
  components_header_videos_content: 'Treningi',
  components_header_men: '',
  components_header_women: '',
  components_header_recipes: 'Przepisy',
  components_header_blog: 'Bloga',
  components_header_members: 'Członkowie',
  hero_text: 'Utrzymuj swoje ciało elastyczne i mocne',
  hero_button_text: 'Rozpocznij swoją przygodę z pilatesem',
  components_ressources_section_title: 'ZASOBY',
  components_ressources_subheader_text:
    'Wszystkie zasoby potrzebne do harmonijnego stylu życia',
  components_ressources_span_text:
    'Odblokuj sekrety dobrego samopoczucia: zapoznaj się z kolekcją ćwiczeń pilates i zdrowych przepisów, które pomogą Ci osiągnąć równowagę w każdym aspekcie swojego życia.',
  components_ressources_card_1_title: 'Wskazówki dotyczące pilatesu',
  components_ressources_card_1_text:
    'Praktyczne porady, które pomogą Ci pogłębić praktykę i udoskonalić swój program.',
  components_ressources_card_2_title: 'Dieta',
  components_ressources_card_2_text:
    'Inteligentne wskazówki żywieniowe, dzięki którym będziesz zdrowszy i silniejszy.',
  components_ressources_link: 'Obejrzyj filmy',
  components_notes_and_google_title_1: 'Pilates',
  components_notes_and_google_title_2: 'Regularna rutyna',
  components_notes_and_google_title_3: 'Utrzymanie diety',
  components_review_google_text: 'Recenzja w Google',
  videos_content_section_title: 'TRENINGI',
  videos_content_section_subheader_text: 'Nasze filmy',
  text_men: '',
  text_women: '',
  videos_content_section_button_more: 'Więcej filmów',
  blog_section_title: 'BLOG',
  blog_section_subheader_text: 'Artykuły i aktualności',
  blog_section_button_more: 'Więcej artykułów',
  components_recipes_section_title: 'PRZEPISY',
  components_recipes_subheader_text: 'Odżywiaj swoje ciało i umysł',
  components_recipes_span_text:
    'Zdrowe przepisy wspierające Twoje ćwiczenia pilates i poprawiające samopoczucie.',
  components_recipes_card_1_title: 'Energetyzujące śniadania',
  components_recipes_card_1_text:
    'Obudź się dzięki żywym i dodającym energii posiłkom.',
  components_recipes_card_3_title: 'Obiady pełne mocy',
  components_recipes_card_3_text:
    'Odkryj pyszne i wygodne przepisy na lunch zaprojektowane z myślą o Twoim aktywnym trybie życia. Od kolorowych sałatek po pełne białka wrapy – te przepisy dodadzą Ci energii i skupią.',
  components_recipes_card_2_title: 'Obiady na regenerację',
  components_recipes_card_2_text:
    'Odkryj różnorodne chude białko, produkty pełnoziarniste i żywe warzywa, aby zapewnić sobie satysfakcjonujący koniec dnia.',
  components_recipes_link: 'Zobacz wszystkie przepisy',
  components_footer_text:
    'Znajdź równowagę, oddychaj głęboko: Twój pilates, Twoja podróż.',
  components_footer_info: 'Informacje',
  components_footer_registration_number: 'Numer rejestracyjny',
  components_footer_legal: 'Prawny',
  components_footer_terms: 'Regulamin',
  components_footer_privacy: 'Polityka prywatności',
  button_load_more: 'Załaduj więcej',
  page_men_videos_content_hero_text: 'Treningi Pilatesa',
  page_recipes_hero_text: 'Przepisy',
  page_recipes_section_title: 'Odżywianie',
  page_recipes_subheader_text: 'Przepisy',
  page_articles_hero_text: 'Nasz blog',
  page_articles_section_title: 'Bloga',
  page_articles_subheader_text: 'Artykuły i aktualności',
  enter_email_title: 'Aby kontynuować, wprowadź swój adres e-mail',
  enter_email_placeholder_email: 'E-mail',
  enter_email_button_enter: 'Wchodzić',
  components_login_success_text: 'Zaloguj się pomyślnie!',
  components_card_link_video_content: 'Obejrzyj teraz',
  components_card_link_recipe: 'Zobacz przepis',
  components_card_link_article: 'Zobacz artykuł',
  france: 'Francja',
  cyprus: 'Cypr',
};
