import { Wording } from '@/types';

export const FITNESS_WORDING_EE: Wording = {
  components_header_videos_content: 'Treeningud',
  components_header_men: 'Meestele',
  components_header_women: 'Naistele',
  components_header_recipes: 'Retseptid',
  components_header_blog: 'Blogi',
  components_header_members: 'liikmed',
  hero_text: 'Hoidke oma keha vormis ja tugevana',
  hero_button_text: 'Alustage oma treeningreisi',
  components_ressources_section_title: 'VAHENDID',
  components_ressources_subheader_text:
    'Kõik ressursid, mida vajate tervislikuks eluviisiks',
  components_ressources_span_text:
    'Toitke oma heaolu: vabastage hulgaliselt ressursse terviklikuks terviseks ja täisväärtuslikuks eluviisiks, sealhulgas eksperdijuhised treeningu, toitumise ja säästvate eluviiside kohta.',
  components_ressources_card_1_title: 'Motivatsioon',
  components_ressources_card_1_text:
    'Lühikesed motivatsioonipuhangud püsivateks treeningharjumusteks.',
  components_ressources_card_2_title: 'Dieet',
  components_ressources_card_2_text:
    'Nutikad toitumisnõuanded tervislikumaks ja tugevamaks.',
  components_ressources_link: 'Vaata videoid',
  components_notes_and_google_title_1: 'Fitnesitreening',
  components_notes_and_google_title_2: 'Regulaarne rutiin',
  components_notes_and_google_title_3: 'Dieedi säilitamine',
  components_review_google_text: "Arvustage Google'is",
  videos_content_section_title: 'TREENINGUD',
  videos_content_section_subheader_text: 'Meie videod',
  text_men: 'Mehed',
  text_women: 'Naised',
  videos_content_section_button_more: 'Rohkem videoid',
  blog_section_title: 'BLOGI',
  blog_section_subheader_text: 'Artiklid ja uudised',
  blog_section_button_more: 'Veel artikleid',
  components_recipes_section_title: 'RETSEPTID',
  components_recipes_subheader_text:
    'Tervislik toitumine teie aktiivseks eluks',
  components_recipes_span_text:
    'Elujõuline toiduvalmistamine: avastage meie kiirete ja toitaineterohkete retseptide abil maitsvat fitnessi maailma.',
  components_recipes_card_1_title: 'Alusta oma päeva',
  components_recipes_card_1_text:
    'Alustage oma hommikut toitainete- ja valgurikaste hommikusöögivalikutega.',
  components_recipes_card_3_title: 'Jõulised lõunasöögid',
  components_recipes_card_3_text:
    'Avastage maitsvaid ja mugavaid lõunasöögi retsepte, mis on loodud teie aktiivse elustiili jaoks. Alates värvilistest salatitest kuni valgurikaste wrappideni – need retseptid hoiavad teid energilisena ja keskendununa.',
  components_recipes_card_2_title: 'Õhtusöögid taastumiseks',
  components_recipes_card_2_text:
    'Avastage erinevaid lahja valke, täisteratooteid ja erksaid köögivilju, et oma päeva rahuldav lõpetada.',
  components_recipes_link: 'Vaadake kõiki retsepte',
  components_footer_text:
    'Suurendage oma jõudu, süttige oma kirg: teie jõusaal, teie ümberkujundamine.',
  components_footer_info: 'Info',
  components_footer_registration_number: 'Registreerimisnumber',
  components_footer_legal: 'Juriidiline',
  components_footer_terms: 'Tingimused',
  components_footer_privacy: 'Privaatsuspoliitika',
  button_load_more: 'Laadige rohkem',
  page_men_videos_content_hero_text: 'Meeste treeningud',
  page_videos_content_menu_all: 'Kõik',
  page_videos_content_menu_biceps: 'Biitseps',
  page_videos_content_menu_triceps: 'Triitseps',
  page_videos_content_menu_back: 'Tagasi',
  page_videos_content_menu_chest: 'Rind',
  page_videos_content_menu_legs: 'Jalad',
  page_videos_content_menu_shoulders: 'Õlad',
  page_videos_content_menu_abdominals: 'Kõhulihased',
  page_women_videos_content_hero_text: 'Naiste treeningud',
  page_recipes_hero_text: 'Retseptid',
  page_recipes_section_title: 'Toitumine',
  page_recipes_subheader_text: 'Retseptid',
  page_articles_hero_text: 'Meie ajaveeb',
  page_articles_section_title: 'Blogi',
  page_articles_subheader_text: 'Artiklid ja uudised',
  enter_email_title: 'Jätkamiseks sisestage oma e-posti aadress',
  enter_email_placeholder_email: 'Meil',
  enter_email_button_enter: 'Sisestage',
  components_login_success_text: 'Sisselogimine õnnestus!',
  components_card_link_video_content: 'Vaadake kohe',
  components_card_link_recipe: 'Vaata retsepti',
  components_card_link_article: 'Vaata artiklit',
  france: 'Prantsusmaa',
  cyprus: 'Küpros',
};
